<template>
    <div>
        <v-sheet rounded="sm" class="ma-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="12">
                        <h2> {{user_data.info.name}}</h2>
                    </v-col>
                    <v-col cols="12" md="12">
                        <table class="pa-4" v-for="team in user_data.info.teams" :key="team.id">
                            <tr>
                                <th>name</th>
                                <th>{{team.name}}</th>
                            </tr>
                            <tr>
                                <th>wallets</th>
                                <td>
                                    <table>
                                        <tr>
                                            <th>id</th>
                                            <th>name</th>
                                            <th>address</th>
                                        </tr>
                                        <tr v-for="w in team.wallets" :key="w.id">
                                            <td>{{w.id}}</td>
                                            <td>{{w.name}}</td>
                                            <td>{{w.address}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <th>members</th>
                                <td>
                                    <table>
                                        <tr>
                                            <th>name</th>
                                            <th>nick name</th>
                                        </tr>
                                        <tr v-for="m in team.members" :key="m.id">
                                            <td>{{m.name}}</td>
                                            <td>{{m.nickname}}</td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
            </v-container>
     
        </v-sheet>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'view-team',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState(['user_data']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>